import icon1 from "../../assets/images/a1.svg";
import icon2 from "../../assets/images/a2.svg";
import icon3 from "../../assets/images/a3.svg";
import icon4 from "../../assets/images/a4.svg";
import icon5 from "../../assets/images/a5.svg";

const bedienungcontrolOptions = [
  { icon: icon1 },
  { icon: icon2 },
  { icon: icon3 },
  { icon: icon4 },
  { icon: icon5 },
];

export default bedienungcontrolOptions;
