export const SET_INSTALLATIONSORT_VALUES = "SET_INSTALLATIONSORT_VALUES";
export const SET_BAUVORHABEN_VALUES = "SET_BAUVORHABEN_VALUES";
export const SET_BAUPHASE_VALUES = "SET_BAUPHASE_VALUES";
export const SET_RAUME_VALUES = "SET_RAUME_VALUES";
export const SET_HERSTELLER_VALUES = "SET_HERSTELLER_VALUES";
export const SET_BEDIENUNG_VALUES = "SET_BEDIENUNG_VALUES";
export const SET_INSTALLATION_SERVICE_VALUES =
  "SET_INSTALLATION_SERVICE_VALUES";
export const SET_HEIMAUTOMATISIERUNG_VALUES = "SET_HEIMAUTOMATISIERUNG_VALUES";
export const SET_VORHANDENER_VALUES = "SET_VORHANDENER_VALUES";
export const SET_FORDERMITTEL_SERVICE_VALUES =
  "SET_FORDERMITTEL_SERVICE_VALUES";
export const SET_WARTUNG_SERVICE_VALUES = "SET_WARTUNG_SERVICE_VALUES";
export const SET_GEWAHRLEISTUNG_VALUES = "SET_GEWAHRLEISTUNG_VALUES";
export const TOGGLE_INTENTION = "TOGGLE_INTENTION";
export const TOGGLE_BEDIENUNG_SELECTION = "TOGGLE_BEDIENUNG_SELECTION";
export const TOGGLE_FUNCTION_SELECTION = "TOGGLE_FUNCTION_SELECTION";
